/**
 * This function is called whenever web chat receives a user-defined response
 * and we need to construct the content for web chat to display.
 */
function clickableTileCarouselUserDefinedResponseHandler(event, instance) {
    const { element, message } = event.data;
  
    // Main HTML structure for the bubbles
    element.innerHTML = `
      <div class="ClickableTileCarousel">
        <div class="tile-container">
          <div class="tile-wrapper"></div>
        </div>
      </div>`;
  
    // Container where individual bubble items will be added
    const tilesContainer = element.querySelector('.tile-wrapper');
    createTiles(tilesContainer, message, instance);
  
    // Mouse & Touch Drag Navigation
    let isDragging = false;
    let startX;
    let scrollLeft;
    
    // Add drag functionality to the bubble container
    tilesContainer.addEventListener("mousedown", (e) => {
      isDragging = true;
      startX = e.pageX - tilesContainer.offsetLeft;
      scrollLeft = tilesContainer.scrollLeft;
    });
  
    tilesContainer.addEventListener("mouseleave", () => {
      isDragging = false;
    });
  
    tilesContainer.addEventListener("mouseup", () => {
      isDragging = false;
    });
  
    tilesContainer.addEventListener("mousemove", (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - tilesContainer.offsetLeft;
      const walk = (x - startX) * 2;
      tilesContainer.scrollLeft = scrollLeft - walk;
    });
  
    // Touch Events for mobile scrolling
    tilesContainer.addEventListener("touchstart", (e) => {
      isDragging = true;
      startX = e.touches[0].pageX - tilesContainer.offsetLeft;
      scrollLeft = tilesContainer.scrollLeft;
    });
  
    tilesContainer.addEventListener("touchend", () => {
      isDragging = false;
    });
  
    tilesContainer.addEventListener("touchmove", (e) => {
      if (!isDragging) return;
      const x = e.touches[0].pageX - tilesContainer.offsetLeft;
      const walk = (x - startX) * 2;
      tilesContainer.scrollLeft = scrollLeft - walk;
    });
  
    //  // Horizontal scrolling with mouse wheel
    //  element.querySelector('.bubble-container').addEventListener("wheel", (e) => {
    //   e.preventDefault();
    //   tilesContainer.scrollLeft += e.deltaY;
    // });
  }
  
  /**
  * Function to create individual bubbles and add click event.
  */
  function createTiles(tilesContainer, message, webChatInstance) {
    const tileData = message.user_defined.tile_data;
  
    // Create each tile based on the data provided
    tileData.forEach((tile) => {
      const { title, subtitle, price, pid, activate_button } = tile;
      const tileItem = document.createElement('div');
      tileItem.classList.add('tile-item');
  
      // Set inner HTML for the bubble item
      tileItem.innerHTML = `
        <div class="cds--tile Carousel__Tile">
        <div class="Carousel__TileText">
          <div class="Carousel__TileTitle">${title}</div>
          <div class="Carousel__TileSubtitle">${subtitle}</div>
        </div>
      `;
  
      // Add click event to send the label text as input to the assistant
      tileItem.addEventListener('click', () => {
        //webChatInstance.send({ input: { text: `${payload}` + `:` + `${pid}` } }, { silent: true });
        webChatInstance.send({ input: { text: `${title}` } }, { silent: false });
      });
  
      // Append the bubble item to the container
      tilesContainer.appendChild(tileItem);
    });
  }
  
  export { clickableTileCarouselUserDefinedResponseHandler };
  