/**
 * This function is called whenever web chat receives a user-defined response and we need to construct the content for web chat
 * to display as a single card.
 */
function singleCardUserDefinedResponseHandler(event, instance) {
    const { element, message } = event.data;

    // Define the main HTML content for the single card
    element.innerHTML = `
      <div class="SingleCard">
        <div class="SingleCard__Container">
          <div class="SingleCard__Text">
            <div class="SingleCard__Title">${message.user_defined.card_data.title}</div>
            <div class="SingleCard__Subtitle">${message.user_defined.card_data.subtitle}</div>
            <div class="SingleCard__Description">${message.user_defined.card_data.description}</div>
          </div>
          <!-- This button will send a message to the assistant -->
          <button type="button" class="SingleCard__Button">
            ${message.user_defined.card_data.activate_button}
          </button>
        </div>
      </div>
    `;

    // Add a click event to the button
    const button = element.querySelector('.SingleCard__Button');
    button.addEventListener('click', () => {
      instance.send(
        { input: { text: message.user_defined.card_data.activate_button } },
        { silent: false }
      );
    });
}

export { singleCardUserDefinedResponseHandler };
