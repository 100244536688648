import Swiper, { A11y, Navigation, Pagination } from 'swiper';

/**
 * This function is called whenever web chat receives a user defined response and we need construct the content for web chat
 * to display.
 */
function cardsUserDefinedResponseHandler(event, instance) {
  const { element, message } = event.data;

  // This will define the main HTML content we want to display in this user defined response. We will add the individual
  // carousel items below.
  element.innerHTML = `
    <div class="Carousel">
      <div class="swiper">
        <div class="swiper-wrapper"></div>
      </div>
      <div class="Carousel__Navigation" >
        <button type="button" class="Carousel__NavigationButton Carousel__NavigationPrevious cds--btn cds--btn--ghost">
          <svg fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true"><path d="M20 24L10 16 20 8z"></path></svg>
        </button>
        <div class="Carousel__BulletContainer"></div>
        <button type="button" class="Carousel__NavigationButton Carousel__NavigationNext cds--btn cds--btn--ghost">
          <svg fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true"><path d="M12 8L22 16 12 24z"></path></svg>
        </button>
      </div>
    </div>`;

  // Once we have the main HTML, create each of the individual slides that will appear in the carousel.
  const slidesContainer = element.querySelector('.swiper-wrapper');
  createSlides(slidesContainer, message, instance);

  // Initialize the Swiper library which is what we are using to control the carousel. We are using a custom pagination
  // element to control pagination and navigation.
  // eslint-disable-next-line no-new
  new Swiper(element.querySelector('.swiper'), {
    modules: [Navigation, Pagination, A11y],
    keyboard: {
      enabled: true,
    },
    pagination: {
      el: element.querySelector('.Carousel__BulletContainer'),
      clickable: true,
      bulletClass: 'Carousel__Bullet',
      bulletActiveClass: 'Carousel__Bullet--selected',
      renderBullet,
    },
    navigation: {
      prevEl: element.querySelector('.Carousel__NavigationPrevious'),
      nextEl: element.querySelector('.Carousel__NavigationNext'),
    },
    slidesPerView: 'auto',
    spaceBetween: 15,
    centeredSlides: true,
    rewind: true,
  });
}

/**
 * This function will create the individual slides to display in the carousel. This will use the "carousel_data" value
 * that is provided as part of the message from the assistant. Each slide will have two buttons. The first button
 * will be a link to another web page. The second button will send a message to the assistant to get more data
 * within web chat for the desired card.
 */
function createSlides(slidesContainer, message, webChatInstance) {
  const carouselData = message.user_defined.carousel_data;

  // Create a slide for each credit card in the message custom data.
  carouselData.forEach((cardData) => {
    const { created_date, category, sub_category, status, trouble_ticket_id, last_update_date, agent_comments, description } = cardData;
    const cardElement = document.createElement('div');
    cardElement.classList.add('swiper-slide');

    cardElement.innerHTML = `
      <div class="cds--tile Carousel__Card">
  <div class="Carousel__CardText">
    <div class="Carousel__CardRow">
      <div class="Carousel__CardLabel">Created:</div>
      <div class="Carousel__CardValue">${created_date}</div>
    </div>
    <div class="Carousel__CardRow">
      <div class="Carousel__CardLabel">Category:</div>
      <div class="Carousel__CardValue">${category}</div>
    </div>
    <div class="Carousel__CardRow">
      <div class="Carousel__CardLabel">Sub-category:</div>
      <div class="Carousel__CardValue">${sub_category}</div>
    </div>
    <div class="Carousel__CardRow">
      <div class="Carousel__CardLabel">Status:</div>
      <div class="Carousel__CardValue Carousel__CardStatus">${status}</div>
    </div>
    <div class="Carousel__CardRow">
      <div class="Carousel__CardLabel">Trouble Ticket ID:</div>
      <div class="Carousel__CardValue">${trouble_ticket_id}</div>
    </div>
    <div class="Carousel__CardRow">
      <div class="Carousel__CardLabel">Last update:</div>
      <div class="Carousel__CardValue">${last_update_date}</div>
    </div>
    <div class="Carousel__CardRow">
      <div class="Carousel__CardLabel">Description:</div>
      <div class="Carousel__CardValue">${description}</div>
    </div>
    <div class="Carousel__CardRow">
      <div class="Carousel__CardLabel">Agent Comments:</div>
      <div class="Carousel__CardValue">${agent_comments}</div>
    </div>
  </div>
  </div>
    `;

    slidesContainer.appendChild(cardElement);
  });
}

/**
 * Renders a custom bullet to be displayed in the pagination element.
 */
function renderBullet(_, className) {
  return `<button class="${className} cds--btn cds--btn--ghost" type="button"></button>`;
}

export { cardsUserDefinedResponseHandler };
